html,
body {
  font-family: "Averia Serif Libre", serif;
  font-optical-sizing: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 700;
  letter-spacing: -0.3px;
  font-style: normal;
}

* {
  /* margin: 0; */
  /* padding: 0; */
  box-sizing: border-box;
}