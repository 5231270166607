.search input {
  padding: 0.5rem 1rem;
  border: 1px solid #757575;
  width: 100%;
  border-radius: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.drawer_right{
  animation: drawer 0.2s;
  animation-timing-function: linear;
  animation-delay: 0.1ms;
}

input{
  outline: none !important;
}
.form-check-input:checked {
  background-color: #9f2089 !important;
  border-color: #9f2089 !important;
}
.form-check-input:focus{
  box-shadow: 0 0 0 0.25rem #c80dfd40;
}

input:focus{
  border-bottom: 1px solid rgb(159, 32, 137) !important;
}


@keyframes drawer {
  from{
    width: 0;
  }to{
    width: 325px;
  }
}

.search i {
  color: #757575;
}

.nav_items {
  list-style: none;
  margin-bottom: 0;
}

.nav_items li {
  border-right: 1px solid gray;
}

.nav_items li span i {
  font-size: 1.2rem;
}

.nav_items span span {
  font-size: 1.1rem;
}

.card .card-body .title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.login_card input {
  border: none;
  border-bottom: 1px solid gray;
  width: 100%;
  outline: none;
}

.hover_box {
  display: none;
}
.hover_box  button:active + .hover_box  {
  height: 0;
  display: none !important;
}
.icon_hover:hover  .hover_box {
  display: inline-block;
}

.icon_hover:hover {
  color: rgb(159, 32, 137);
}

.btn_profile {
  margin-bottom: 1rem !important;
  position: relative;
  top: 0;
  display: inline-block;
  width: 100%;
  padding: 0.2rem;
  font-weight: 500;
}

.btn_profile::after {
  content: "";
  border-radius: 20px;
  background: rgb(159, 32, 137);
  display: inline-block;
  height: 2px;
  width: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: 0.4s all linear;
  transform: translate(-50%, 0%);
}

.btn_profile:hover.btn_profile::after {
  width: 50% !important;
}

.btn_profile:hover {
  color: rgb(159, 32, 137);
}

.product_Detail_img {
  width: 100%;
  height: 300px;
}


.btn {
  border: none !important;
  box-shadow: none !important;
}

.btn-primary {
  background: rgb(159, 32, 137) !important;
  color: white !important;

}

.cart_link:hover {
  color: rgb(159, 32, 137);
  cursor: pointer;
}

.search {
  width: 500px;
}

.bt-primary {
  background-color: #9f2089 !important;
  color: white !important;
}

.cart_item_img {
  width: 100px;
  height: 130px;
}



.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: #ffffff7d;
}


.loader::after {
  content: "";
  width: 0;
  height: 100vh;
  background: white;
  display: inline-block;
  position: absolute;
  top: 0;
  animation: screenEffect 0.2s;
  animation-timing-function: linear;
  left: 0;
}

.loader::before {
  content: "";
  width: 0;
  height: 100vh;
  background: white;
  display: inline-block;
  position: absolute;
  top: 0;
  animation: screenEffect 0.2s;
  animation-timing-function: linear;
  right: 0;
}

.hover_box {
  transition: 0.3s all ease-in-out;
}

@keyframes screenEffect {
  from {
    width: 50%;
  }

  to {
    width: 0px;
  }
}

@media screen and (max-width:400px) {
  .product_Detail_img {
    height: 180px !important;
  }
}

@media screen and (max-width:678px) {
  .hover_box {
    left: -10rem !important;
    width: 200px !important;
  }
}

@media screen and (max-width:575px) {
  header {
    justify-content: space-between;
  }

  .search {
    display: none;
  }

  .row a {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .card {
    border-radius: 0;
  }

  .card img {
    border-radius: 0 !important;
  }

  .login_card {
    width: 100% !important;
  }

  .cart_container>.row>div {
    padding: 0 !important;
    border: none;
  }

  .cart_container>.card {
    margin: 0 !important;
  }
}

@media screen and (max-width:998px) {
  .nav_items>li:first-child {
    display: none !important;
  }


}

@media screen and (max-width:1200px) {
  .search {
    width: 350px;
  }
}